
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { commonModule, installationsModule, itemsModule, partnersModule } from '@/store/modules/store-accessor'
    import { taskStatusToColor,  taskStatusToName, taskColor, getOptimalTextColor, primaryItemText, formatOnlyDate, formatDateWithoutSeconds } from '@/helpers/data'
    import { TimeUtil } from '@/helpers/TimeUtil'
  
    @Component
    export default class PartnersSideviewTasksTab extends Vue {
      @Prop() installation: Record<string, any>;

      completeWithJobButtonLoader = false;
  
      mounted() {
        commonModule.getPersonnelPartnersCombo();
        commonModule.getActionCombo();
        commonModule.getDurationCombo();
        itemsModule.getAllCompanyPrimaryInventoryItems();
      }

      get tasks() {
        return partnersModule.currentPartner.tasks;
      }
  
    get tasksExist() {
      return this.tasks.length > 0
    }

    get pendingTasks() {
      return partnersModule.currentPartner.pending_tasks;
    }
    
    get pendingTasksExist() {
      return this.pendingTasks.length > 0
    }

    formatDate(item: Record<string, any>) {
      if (!item.due_at) {
        return '-';
      }
      if(item.all_day) {
        return formatOnlyDate(item.due_at)
      }
      return formatDateWithoutSeconds(item.due_at);
    }
  
    formatDateTime (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }
  
    taskIsComplete(task: any) {
      if(task.task_status_id == 1) 
        return true;
      else
        return false;
    }
  
    dateToColor(date: any) {
        return taskColor(date)
    }
  
    optimalTextColor(color: string) {
      return getOptimalTextColor(color);
    }
  
    statusToName(status: number) {
      return taskStatusToName(status)
    }
  
    statusToColor(status: number){
      return taskStatusToColor(status)
    }
  
    editTask (task: any) {
      commonModule.showModal({ name: 'edit-task-modal', payload: { task: task }})
    }
  
    completeTask (task: any) {
      commonModule.showModal({ name: 'complete-task-modal', payload: { task: task}})
    }
  
    completeTaskWithAppointment (task: any){
      commonModule.showModal({ name: 'add-appointment-modal', payload: { task_id: task.id, installation: this.installation, action_type_id: task.action_type_id, partner_id: task.partner_id }})
    }

    async completeTaskWithJob (task: any){
      this.completeWithJobButtonLoader = true;
      const assignee = partnersModule.currentPartner.info.is_business ?
                        `${partnersModule.currentPartner.info.entity_name} (${partnersModule.currentPartner.info.firstname} ${partnersModule.currentPartner.info.lastname})`
                        : `${partnersModule.currentPartner.info.firstname} ${partnersModule.currentPartner.info.lastname}`
      const installation = await installationsModule.getInstallationInfo(task.installation_id);
      commonModule.showModal({ name: 'add-job-modal', payload: { task_id: task.id, installation: installation, action_type_id: task.action_type_id, partner_id: task.partner_id, assignee: assignee, notes: task.notes }});
      this.completeWithJobButtonLoader = false;
    }
  
    deleteTask (task: any){
      commonModule.showModal({ name: 'delete-task-modal', payload: { task: task}})
    }

    showReminderLabel(task: Record<string, any>) {
      return task.title && task.title.length > 0 ? task.title : task.category && task.category.includes(1) ? this.$i18n.t("reminder_label") : '-' ;
    }

    getItemText(id: number) {
      return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
    }
  
  };
  