import { render, staticRenderFns } from "./AddTaskModal.vue?vue&type=template&id=abc4646c&scoped=true&class=fill-height&"
import script from "./AddTaskModal.vue?vue&type=script&lang=ts&"
export * from "./AddTaskModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddTaskModal.vue?vue&type=style&index=0&id=abc4646c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc4646c",
  null
  
)

export default component.exports