
  import { commonModule, dashboardModule, installationsModule, itemsModule, tasksModule } from '@/store/modules/store-accessor'
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  import { TimeUtil } from '@/helpers/TimeUtil'
  import { taskColor, getOptimalTextColor, primaryItemText, formatOnlyDate, formatDateWithoutSeconds } from "@/helpers/data";
  
  @Component
  export default class DashboardPendingTasksTable extends Vue {
    @Prop() readonly headers: any;
    @Prop() readonly items: any;
    @Prop() readonly search: string;
    @Prop() readonly isLoading: boolean;

    formatDate(item: Record<string, any>) {
      if (!item.due_at) {
        return '-';
      }
      if(item.all_day) {
        return formatOnlyDate(item.due_at)
      }
      return formatDateWithoutSeconds(item.due_at);
    }

    hasSpace(note: string) {
      if (note) {
        return note.trim().includes(' ')
      }
      else {
        return false
      }
    }

    dateToColor(date: any) {
      return taskColor(date)
    }

    optimalTextColor(color: string) {
      return getOptimalTextColor(color);
    }

    maxWordSize(note: string) {
      if (note) {
        let words = note.split(' ')
        return Math.max(...(words.map(el => el.length)));
      }
      else {
        return 0;
      }
    }

    async openInstallationSideViewOrTaskModal (task: any) {
      if(!task.installation_id) {
        dashboardModule.setIsLoadingPendingTasks(true);
        tasksModule.unsetCurrentTask();
        await tasksModule.getTaskDetails(task.id);
        commonModule.showModal({ name: 'open-orphan-task-modal' });
        dashboardModule.setIsLoadingPendingTasks(false);
      } else {
        try {
          dashboardModule.setIsLoadingPendingTasks(true);
          await installationsModule.getInstallationInfo(task.installation_id);
          dashboardModule.setIsLoadingPendingTasks(false);
          commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: task.installation_id, tab: 3, taskId: task.id } })
        } catch (error) {
          dashboardModule.setIsLoadingPendingTasks(false);
        }
      }
    }

    getItemText(id: number) {
      return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
    }

    nameText(item: Record<string, any>) {
      if(item.name.length === 1) {
        return '-';
      }
      return item.is_business ? `${item.entity_name} (${item.name})` : item.name
    }

  }

  