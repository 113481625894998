
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { actionTypesModule, appointmentsModule, dashboardModule, tasksModule } from '@/store/modules/store-accessor'
import { getOptimalTextColor } from "@/helpers/data";
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";

import _ from "lodash";

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class QuickAddTask extends Vue {
    @Prop() readonly step: number;
    @Prop() readonly currentStep: any;
    @Prop() readonly installation: any;

    date = false;
    time = false;
    addTaskLoading = false;
    assigneeLoading = false;
    actionLoading = false;
    isCompleted = false;
    dateMenu = false;
    timeMenu = false;

    updateAssignee(assignee: any) {
        this.taskModel.assignee_id = assignee;
    }

    showNewInstallationForm = true;
    addClientAndInstallation = true;

    taskModel = {
      due_at: '',
      assignee_id: '',
      installation_id: '',
      task_status_id: -1,
      title: '',
      notes: '',
      action_type_id: null,
      scheduled_start_time: '12:00',
      all_day: false,
    }

    notesRule = [
        (val: any) => {
            if(val != null)
                return val.length <= 1000 || this.$i18n.t("notesLengthRule");
            else 
                return true;
        },
    ]

    titleRule = [
      (val: any) => !!val || this.$i18n.t("title_required"),
      (val: any) => val.length <= 300 || this.$i18n.t("form_title_length_rule"),
    ]

    get actionsCombo() {
        return actionTypesModule.actionTypesCombo;
    }

    get isActionsLoading () {
        return this.actionLoading
    }

    get showTimeInput() {
        return !this.taskModel.all_day;
    }

    optimalTextColor(color: string) {
        return getOptimalTextColor(color);
    }

    @Watch('currentStep')
    async stepUpdate () {
        return 0;
    }

    async doAddTask () {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            let taskModelCopy = _.cloneDeep(this.taskModel);
            taskModelCopy.task_status_id = this.isCompleted ? 3 : 2; // -> 3 completed -> 2 pending
            (taskModelCopy.installation_id as any) = null;

            this.addTaskLoading = true;

            await tasksModule.addTask(taskModelCopy);

            switch(this.$route.name) {
                case 'Dashboard':
                    dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
                break;
                case 'Tasks':
                    tasksModule.getTasks(tasksModule.filters);
                    tasksModule.getPendingTasks();
                    break;
                case 'Calendar':
                    appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
                    break;
            }

            this.addTaskLoading = false;

            this.$emit('close-modal');
        }
    }

    callFunction() {
        if(this.addClientAndInstallation) {
            this.nextStep();
        } else {
            this.doAddTask();
        }
    }

    async nextStep() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            if((this.addClientAndInstallation)) {
                this.taskModel.task_status_id = this.isCompleted ? 3 : 2; // -> 3 completed -> 2 pending
                this.$emit('next-step', this.taskModel);
            }
            else {
                this.$emit('close-modal')
            }
        }
    }



    prevStep() {
        this.$emit('prev-step')
    }

}
