
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, companyModule, installationsModule, itemsModule, tasksModule } from '@/store/modules/store-accessor'
import { taskStatusToColor,  taskStatusToName, formatOnlyDate, taskColor, getOptimalTextColor, primaryItemText, templatesFilenameLanguage, formatDateWithoutSeconds } from '@/helpers/data'
import { TimeUtil } from '@/helpers/TimeUtil'
import dayjs from 'dayjs';
import FileSaver from 'file-saver';

@Component
export default class InstallationsSideviewTasksTab extends Vue {
  @Prop() installation: Record<string, any>;
  @Prop() taskId: number;

  expansionTaskIdIndex = null as number | null;
  expansionPendingTaskIdIndex = null as number | null;

  isDownloadingTaskPdf = false;
  completedTasksStatus = [3, 4, 5];

  mounted() {
    commonModule.getPersonnelCombo();
    commonModule.getPersonnelPartnersCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    itemsModule.getAllCompanyPrimaryInventoryItems();
    this.expansionTaskIdIndex =  this.tasks.findIndex((i: any) => i.id == this.taskId); //This is needed to find the index in the expansion panel
    this.expansionPendingTaskIdIndex =  this.pendingTasks.findIndex((i: any) => i.id == this.taskId); //This is needed to find the index in the expansion panel
  }

  @Watch("installation")
  updateTasks() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
  }

  get tasks() {
    return installationsModule.currentInstallation.tasks;
  }
  
  get tasksExist() {
    return this.tasks.length > 0
  }

  get pendingTasks() {
    return installationsModule.currentInstallation.pending_tasks;
  }
    
  get pendingTasksExist() {
    return this.pendingTasks.length > 0
  }

  formatDate(item: Record<string, any>) {
      if (!item.due_at) {
        return '-';
      }
      if(item.all_day) {
        return formatOnlyDate(item.due_at)
      }
      return formatDateWithoutSeconds(item.due_at);
    }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  taskIsComplete(task: any) {
    if(task.task_status_id == 1) 
      return true;
    else
      return false;
  }

  dateToColor(date: any) {
      return taskColor(date)
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  statusToName(status: number) {
    return taskStatusToName(status)
  }

  statusToColor(status: number){
    return taskStatusToColor(status)
  }

  addTask(){
    commonModule.showModal({ name: 'add-task-modal', payload: { installation: this.installation}})
  }

  editTask (task: any){
    commonModule.showModal({ name: 'edit-task-modal', payload: { task: task }})
  }

  completeTask (task: any){
    commonModule.showModal({ name: 'complete-task-modal', payload: { task: task}})
  }

  completeTaskWithAppointment (task: any){
    commonModule.showModal({ name: 'add-appointment-modal', payload: { task_id: task.id, installation: this.installation, action_type_id: task.action_type_id, assignee_id: task.assignee_id, notes: task.notes, installation_item_id: task.installation_item_id}})
  }

  completeTaskWithJob (task: any){
    commonModule.showModal({ name: 'add-job-modal', payload: { task_id: task.id, installation: this.installation, action_type_id: task.action_type_id, assignee: task.assignee_id, notes: task.notes, installation_item_id: task.installation_item_id}})
  }

  deleteTask (task: any){
    commonModule.showModal({ name: 'delete-task-modal', payload: { task: task}})
  }

  async donwloadTaskPdf(task: Record<string, unknown>) {
    this.isDownloadingTaskPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'taskId': task.id, 'utcOffset': utcOffset, language: localStorage.getItem('lang')  };

    if(task.id) {
      const company = await companyModule.getCompanyInfo();
      const filename = `${templatesFilenameLanguage()}_${company.entity_name}_${this.installation.info.tag_id}_task_${task.id}.pdf`.replace(/ /g, '_');
      const pdf = await  tasksModule.getTaskPdfExportAction(data);
      const file = new File([pdf], filename);
      FileSaver.saveAs(file);
    }
    this.isDownloadingTaskPdf = false;
  }

  showReminderLabel(task: Record<string, any>) {
      return task.title && task.title.length > 0 ? task.title : task.category && task.category.includes(1) ? this.$i18n.t("reminder_label") : '-' ;
  }

  getItemText(id: number) {
    return primaryItemText(id, itemsModule.allCompanyPrimaryItems);
  }

};
