import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import TasksApi from '@/api/tasks-api';
import { installationsModule, partnersModule } from '@/store/modules/store-accessor'
import _ from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

@Module({ namespaced: true, name: 'tasks' })
export default class TaskModule extends VuexModule {
  public tasks: TaskDto[] = [];
  public pendingTasks: TaskDto[] = [];

  public isLoadingPendingTable = true;
  public isLoadingTable = false;
  public params = new URLSearchParams();
  public filtersParams = new URLSearchParams();
  public search = '';
  public filters = '';
  public queryObject: any = {};
  public currentTask: TaskDto = {} as TaskDto;

  @Action({ commit: 'setTasks'})
  async getTasks (query: string): Promise<TaskDto[]> {
    this.setIsLoadingTable(true);
    const taskApi = new TasksApi()
    const tasks = await taskApi.getAllTasks(query);
    this.setIsLoadingTable(false);

    return tasks
  }

  @Action({ commit: 'setPendingTasks'})
  async getPendingTasks (): Promise<TaskDto[]> {

    const taskApi = new TasksApi()
    const tasks = await taskApi.getPendingTasks();

    return tasks
  }

  @Action
  async addTask(data: Record<string, any>) {
    data.assignee_id = data.assignee_id.id;

    if(data.due_at == null || data.due_at == undefined || data.due_at == '')  {
      delete data.due_at;
    }

    if(data.all_day === false && data.due_at && data.scheduled_start_time) {
      data.due_at = dayjs(`${data.due_at} ${data.scheduled_start_time}`);

      delete data.scheduled_start_time;
    }

    if(data.installation_item_id && data.installation_item_id.id) {
      data.installation_item_id = data.installation_item_id.id;
    } else {
      delete data.installation_item_id;
    }

    const taskApi = new TasksApi()
    const response = await taskApi.createTask(data)
    if(data.installation_id) {
      await installationsModule.getInstallationInfo(data.installation_id);
    }
    return response;
  }

  @Action
  async editTask(data: Record<string, any>) {
    const dataTask = _.cloneDeep(data);
    if(data.assignee) {
      dataTask.assignee_id = data.assignee.id
    } else {
      delete dataTask.assignee_id;
    }

    if(data.installation_item_id && data.installation_item_id.id) {
      dataTask.installation_item_id = dataTask.installation_item_id.id;
    } else {
      delete dataTask.installation_item_id;
    }

    if(!data.due_at) {
      delete dataTask.due_at
    }

    if(dataTask.all_day === false && dataTask.due_at && dataTask.scheduled_start_time) {
      dataTask.due_at = dayjs(`${dataTask.due_at} ${dataTask.scheduled_start_time}`);

      delete dataTask.scheduled_start_time;
    }

    const taskApi = new TasksApi()
    await taskApi.updateTask(dataTask.id, dataTask)
    if(dataTask.installation_id) {
      await installationsModule.getInstallationInfo(dataTask.installation_id);
    } else {
      await this.getTaskDetails(dataTask.id);
    }

    if(data.partner_id) {
      partnersModule.getPartnersInfo(dataTask.partner_id);
    }

    this.getTasks(this.filters);
    this.getPendingTasks();
  }

  @Action
  async completeTask(data: Record<string, any>) {

    const taskApi = new TasksApi()
    await taskApi.completeTask(data.id, data)
    if(data.installation_id) {
      await installationsModule.getInstallationInfo(data.installation_id);
    } else {
      this.getTasks(this.filters);
      this.getPendingTasks();
    }
    if(data.partner_id) {
      await partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async deleteTask(data: Record<string, any>) {

    const taskApi = new TasksApi()
    await taskApi.deleteTask(data.id)
    if(data.installation_id) {
      await installationsModule.getInstallationInfo(data.installation_id);
    }
    if(data.partner_id) {
      await partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async getTaskPdfExportAction(data: Record<string, any>) {
    const taskApi = new TasksApi()
    const response = await taskApi.getTaskPdfExport(data);
    const blob = new Blob([response], { type: "application/pdf" });
    return blob
  }

  @Action
  async getTasksPdfExportAction({ query, data } : { query: string, data: Record<string, any> }) {
    const taskApi = new TasksApi();
    const response = await taskApi.getTasksPdfExport(query, data);
    const blob = new Blob([response], { type: "application/pdf" });
    return blob
  }

  @Action({ commit: "setCurrentTask" })
  async getTaskDetails(currentTaskId: string): Promise<TaskDto> {

    const taskApi = new TasksApi();
    const taskDetails = await taskApi.getTaskDetails(currentTaskId);
    return taskDetails;
  }

  @Mutation
  public unsetCurrentTask(): void {
    this.currentTask = {} as TaskDto;
  }


  // --- Mutations ---

  @Mutation
  public setTasks (tasks: TaskDto[]): void {
    this.tasks = tasks;
  }

  @Mutation
  public setPendingTasks (pendingTasks: TaskDto[]): void {
    try {
      this.pendingTasks = pendingTasks;
      this.isLoadingPendingTable = false;
    } catch (error) {
      this.isLoadingPendingTable = false;
    }
  }

  @Mutation
  public setIsLoadingPendingTable(isLoadingPendingTable: boolean): void {
    this.isLoadingPendingTable = isLoadingPendingTable;
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public clearTasks() :void {
    this.tasks = [];
  }

  @Mutation
  public setParams(params: URLSearchParams): void {
    if(this.search != '') {
      params.set('search', this.search);
    }
    this.params = params
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }

  @Mutation
  public setCurrentTask(taskInfo: TaskDto): void {
    this.currentTask = taskInfo;
  }
}